import React from "react";
import {
  RDevText,
  RHeader,
  RPApp,
  RPDescription,
  RPStatus,
  RPTodo,
  RPTools,
  RTabText,
  RTitleWrapper
} from "../Common/Resume";
import UpdateShare from "../Common/UpdateShare";
import PageWrapper from "./PageWrapper";
import { dev, nav } from "./data";

const ResumeJan = () => (
  <PageWrapper
    title="Chaal's Blog > Resume"
    nav={nav.slice(0, 2)}
    cName="Chaal Pritam"
    cYear="2019"
    cLink="/"
    pTitle="React"
    pLink="https://reactjs.org/"
    pAText="JS library for building UI"
    hTitle="AWS S3"
    hLink="https://aws.amazon.com/s3/"
    hAText="AWS S3"
  >
    <div class="inner cover post post-body resume">
      <RHeader />
      <RTitleWrapper title="Education">
        <p>Bachelor of Technology | Biotechnology [ 2007 - 2012 ]</p>
        <p>Dropout | 2010</p>
      </RTitleWrapper>
      <RTitleWrapper title="Dev History">
        <p>{`Freelance - { Web | Mobile | Cloud | Bigdata }`}</p>
        {dev &&
          dev
            .slice(0, 6)
            .map(d => (
              <RDevText
                title={d.title}
                startDate={d.startDate}
                endDate={d.endDate}
              />
            ))}
        <p>Other</p>
        {dev &&
          dev
            .slice(6, 8)
            .map(d => (
              <RDevText
                title={d.title}
                startDate={d.startDate}
                endDate={d.endDate}
              />
            ))}
        <p>Active</p>
        {dev &&
          dev
            .slice(8)
            .map(d => (
              <RDevText
                title={d.title}
                startDate={d.startDate}
                endDate={d.endDate}
              />
            ))}
      </RTitleWrapper>
      <RTitleWrapper title="Recent Work">
        <p>
          Full Stack Developer -{" "}
          <a
            href="https://angel.co/fanlytiks"
            target="_blank"
            rel="noopener noreferrer"
            title="Fanlytiks - Angel"
          >
            @Fanlytiks
          </a>{" "}
          [ Feb, 2018 - Nov, 2018 ]
        </p>
        <RTabText
          title="Building the Analytics Dashbord's front-end and back-end architecture from
          scratch"
        />
        <RTabText title="FrontEnd -> React, Redux, Mobx, Mobx-State-Tree, GraphQL(Apollo GraphQL)" />
        <RTabText title="BackEnd -> Express, Sequelize, GraphQL(Apollo GraphQL), Postgres, Redshift, Redis" />
        <RTabText title="Other -> Facebook & Twitter API Integration, Android Library" />
      </RTitleWrapper>
      <RTitleWrapper title="Recent Projects">
        <p>Qubits</p>
        <RPStatus
          status="Jan, 2016 - Dec, 2016"
          launch="Re-Launching May, 2019"
        />
        <RPApp
          title="Website"
          link="http://thequbits.herokuapp.com/"
          aText="The Qubits Website"
          desc="Deployed on Heroku [w/o Crawling & Scheduling]"
        />
        <RPTools tools="Django & DRF, Scrappy, Celery, Rest API, Redis, Nginx, Heroku, AWS S3 & RDS" />
        <RPApp
          title="Android App"
          link="https://play.google.com/store/apps/details?id=com.chaalpritam.apps.thequbitsshort"
          aText="The Qubits Android App"
          desc="Published - Beta version"
        />
        <RPTools tools="Retrofit2, Rxjava2, Realm, Dagger2, Glide, ButterKnife, OkHttp3, MVC" />
        <RPDescription desc="Site for getting latest information related to Science, Technology & Space." />
        <RPTodo todo="Frontend to React & App to React Native with GraphQL" />
        <br />
        <p>Throttlers</p>
        <RPStatus status="Oct, 2017 - Present" launch="Launching Sep, 2019" />
        <RPApp
          title="Website"
          link="http://throttlers.in/"
          aText="The Throttlers Website"
          desc="Deployed on Heroku - beta"
        />
        <RPTools tools="JavaScript ES6, GraphQL, ReactJs, Redux, ExpressJS, Postgresql, SequelizeJS," />
        <RPApp title="Mobile App" link="" aText="" desc="Launching Oct, 2019" />
        <RPTools tools="JavaScript, React Native, GraphQL, Redux, Postgresql" />
        <RPDescription desc="Site for motorcyclist, riders and travellers with social networking and marketplace." />
        <br />
        {/* <p>BooksReadBy</p>
        <RPStatus status="April, 2017 - Present" launch="Active" />
        <RPApp
          title="Website"
          link="http://booksreadby.com/"
          aText="BooksReadBy Website"
          desc="Deployed on Heroku"
        />
        <RPTools tools="Django & DRF, Rest API, Heroku, Gunicorn, SQLite, Postgresql, AWS S3 & RDS" />
        <RPApp
          title="Android App"
          link="https://play.google.com/store/apps/details?id=com.chaalpritam.apps.booksreadby"
          aText="BooksReadBy Android App"
          desc="Published"
        />
        <RPTools tools="Retrofit2, Rxjava2, Realm, Dagger2, Glide, ButterKnife, OkHttp3, MVC" />
        <RPDescription desc="BooksReadBy provides a curated list of books that read by famous personalities." />
        <RPTodo todo="Frontend to React & App to React Native" />
        <br /> */}
        <p>HashHeal</p>
        <RPStatus status="Dec, 2018 - Present" launch="Launching July, 2019" />
        <RPApp
          title="Website"
          link="http://hashheal.com"
          aText="Hasheal Website"
          desc="Deployed on AWS"
        />
        <RPTools tools="TS, GraphQL, React, Mobx & MST, Express, Postgres, TypeORM, TypeGraphQL, D3" />
        <RPApp title="Mobile App" link="" aText="" desc="Launching Aug, 2019" />
        <RPTools tools="React Native, TS GraphQL, Redux, Express, Postgres, TypeORM, TypeGraphQL," />
        <RPDescription desc="Blockchain based analytics dashboard tool for all kind of activity trackers." />
        <br />
        {/* <p>GitGeek</p>
        <RPStatus status="Dec, 2018 - Present" launch="Launching March, 2019" />
        <RPApp
          title="Website"
          link="http://gitgeek.me"
          aText="GitGeek Website"
          desc="Deployed on AWS"
        />
        <RPTools tools="TS, GraphQL, React, Mobx & MST, Express, Postgres, TypeORM, TypeGraphQL" />
        <RPDescription desc="Marketplace for Code Reviews." />
        <br /> */}
        {/* <p>Binocular</p>
        <RPStatus status="Oct, 2016 - July, 2017" launch="discontinued" />
        <RPApp
          title="Website"
          link="http://binoculardev.herokuapp.com/"
          aText="Binocular Website"
          desc="Deployed on Heroku"
        />
        <RPTools tools="Python, Django & DRF, Html5, Css3, Js, Jquery, Json, Rest API, Postgresql," />
        <RPTools tools="Heroku, Gunicorn, SQLite, Postgresql, AWS S3 & RDS, Ubuntu" />
        <RPApp
          title="Android App"
          link="https://play.google.com/store/apps/details?id=com.chaalpritam.apps.binocular"
          aText="Binocular Android App"
          desc="Published - Beta version"
        />
        <RPTools tools="Retrofit2, Rxjava2, Realm, Dagger2, Glide, ButterKnife, OkHttp3, MVC" />
        <RPTools tools="Robolectric, Espresso, Mockito, Hamcrest" />
        <RPDescription desc="Binocular enables people to find favourite foods around their locality." /> */}
      </RTitleWrapper>
    </div>
    <UpdateShare updated="15 Jan 2019" postTitle="Resume" />
  </PageWrapper>
);

export default ResumeJan;
