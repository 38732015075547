import React from "react";
import MainContent from "../Common/MainContent";
import PageWrapper from "./PageWrapper";
import { nav } from "./data";

const About = () => (
  <PageWrapper
    title="Chaal's Blog"
    nav={nav}
    cName="Chaal Pritam"
    cYear="2016"
    cLink="/"
    pTitle="Flask"
    pLink="http://flask.pocoo.org/"
    pAText="Flask | Python Microframework"
    hTitle="Heroku"
    hLink="http://heroku.com"
    hAText="Heroku"
  >
    <MainContent postTitle="About" updated="10 May 2016" />
  </PageWrapper>
);

export default About;
