import React, { Fragment } from "react";
import SocialMediaLinks from "../Common/SocialMediaLinks";
import { sml } from "../Pages/data";
import PostTitle from "./PostTitle";
import UpdateShare from "./UpdateShare";

const MainContent = ({ postTitle, updated }) => (
  <Fragment>
    <PostTitle postTitle={postTitle} />
    <div class="inner cover post post-body">
      <p />
      <center>
        <strong>!</strong>
      </center>
      <p>I'm Chaal Pritam, a freelance developer living in Bangalore.</p>
      {/* <p>Currently Bootstrapping my own startup <a href="http://mindspeakai.com" target="_blank" title="Mind Speak AI">Mind Speak</a>. My skillset are <a href="skill" target="_blank">here</a>.</p> */}
      <p>
        Currently Bootstrapping my own ideas into products. My skill set is
        <a href="/skill" target="_blank">
          {" "}
          here
        </a>
        .
      </p>
      <br />
      <p>
        I'm an Open source enthusiast who has been dazzled by the spirit of
        Linux and I use ubuntu, fedora, RedHat and kali Linux operating systems
        for my daily use.
      </p>
      <br />
      <p>
        I have tutored people on Open source technologies and made them
        comfortable on using Open source platform for their daily use in the
        various desktop environment.
      </p>
      <br />
      <p>
        Have made some video tutorials on openstack &#38; hadoop, checkout
        {" >> "}
        <a
          href="https://www.youtube.com/user/chaalpritam"
          target="_blank"
          rel="noopener noreferrer"
        >
          youtube
        </a>
        {" << "}
        &#38;
        {" >> "}
        <a
          href="https://www.chaalpritam.blogspot.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          blog
        </a>
        {" << "}
      </p>
      <br />
      <p>
        Have huge passion for {"{design > develop > build}"} ing things from
        scratch.
      </p>
      <p>[ website, mobile app, cloud, drones, Arduino, raspberry pi ]</p>
      <br />
      <p>I am a strong supporter of Wikileaks, Anonymous and Snowden.</p>
      <br />
      <p>Love to Code &#38; drink Tea, Wannabe traveller.</p>
      <br />
      <p>
        Curious to learn anything related to Science, Technology &#38; Space.
      </p>
      <br />
      <p>
        Email me with any query at
        <a href="mailto:me@chaalpritam.com"> me@chaalpritam.com</a>
      </p>
      <hr />
      <p />
      {sml && <SocialMediaLinks sml={sml} />}
    </div>
    <UpdateShare updated={updated} postTitle={postTitle} />
  </Fragment>
);

export default MainContent;
