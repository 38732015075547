import React from "react";
import "./FilterContentLayout.scss";

export const Grid4Layout = ({ children}) => <div className="cp-filter-grid-4-layout">{children}</div>;

export const Grid3Layout = ({ children }) => <div className="cp-filter-grid-3-layout">{children}</div>;

export const Grid2Layout = ({ children }) => <div className="cp-filter-grid-2-layout">{children}</div>;

export const Grid1Layout = ({ children }) => <div className="cp-filter-grid-1-layout">{children}</div>;

export const LogoLayout = ({title, image}) => (
  <div 
  className="cp-filter-logo-layout"
  style={{backgroundImage: `url(${image})`, 
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat"
  }}>
  <p>{title}</p>
  </div>
);

export const IllustrationLayout = ({title, image}) => (
  <div className="cp-filter-illustration-layout"
  style={{backgroundImage: `url(${image})`, 
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat"
  }}>
  <p>{title}</p>
  </div>
);

export const HobbyDevLayout = () => (
  <div className="cp-filter-hobbydev-layout">hobby dev layout</div>
);

export const WebsiteLayout = () => (
  <div className="cp-filter-website-layout">website layout</div>
);

export const LandingPageLayout = ({ title, image }) => (
  <div 
  className="cp-filter-landingpage-layout"
  style={{backgroundImage: `url(${image})`, 
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat"
  }}>
  <p>{title}</p>
  </div>
);

export const DashboardLayout = () => (
  <div className="cp-filter-dashboard-layout">dashboard layout</div>
);

export const UIDesignLayout = () => (
  <div className="cp-filter-uidesign-layout">uidesign layout</div>
);
