import React, { Fragment } from "react";

const UpdateShare = ({ updated, postTitle }) => (
  <Fragment>
    <hr style={{ marginBottom: "2px" }} />
    <div class="inner cover container post post-foot">
      <div class="post-foot-left">
        Last Updated: <time datetime={{ updated }}>{updated}</time>
      </div>
      <div class="post-foot-right">
        <a
          href={`http://twitter.com/share?text=${postTitle}&amp;url=${
            window.location.href
          }`}
          onclick="window.open(this.href, 'twitter-share', 'width=550,height=235');return false;"
        >
          <i class="fa fa-twitter" />{" "}
        </a>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${
            window.location.href
          }`}
          onclick="window.open(this.href, 'facebook-share', 'width=580,height=296');return false;"
        >
          <i class="fa fa-facebook" />{" "}
        </a>
        <a
          href={`https://plus.google.com/share?url=${window.location.href}`}
          onclick="window.open(this.href, 'google-plus-share', 'width=490,height=530');return false;"
        >
          <i class="fa fa-google-plus-square" />
        </a>
      </div>
    </div>
  </Fragment>
);

export default UpdateShare;
