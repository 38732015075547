import React from "react";
import UpdateShare from "../Common/UpdateShare";
import PageWrapper from "./PageWrapper";
import { nav } from "./data";

const ResumeAug = () => (
  <PageWrapper
    title="Chaal's Blog > Resume"
    nav={nav.slice(0, 2)}
    cName="Chaal Pritam"
    cYear="2016"
    cLink="/"
    pTitle="Flask"
    pLink="http://flask.pocoo.org/"
    pAText="Flask | Python Microframework"
    hTitle="Heroku"
    hLink="http://heroku.com"
    hAText="Heroku"
  >
    <div class="inner cover post post-body resume">
      <p>
        <strong>Ezhumalai M</strong>{" "}
        <span class="res-right">
          <a href="mailto:chaalpritam@gmail.com">chaalpritam@gmail.com</a>
        </span>
      </p>
      <p>
        aka{" "}
        <a
          href="http://twitter.com/chaalpritam"
          target="_blank"
          rel="noopener noreferrer"
          title="Twitter"
        >
          @chaalpritam
        </a>
        <span class="res-right">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+91 7598 2190 77
        </span>
      </p>
      <br />
      <p>{`{Self-taught, Curious to learn, Loves to code, Passionate techy, Develop's ideas into product's}`}</p>
      <br />
      <p>
        <strong>Skills</strong>
      </p>
      <br />
      <p>
        <span class="res-left">
          Expert&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Html5, Css3, Bootstrap, Jquery, Openstack, Hadoop & Related packages,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Ubuntu, Xml, Json, Mysql, Sqlite, Markdown, Ngnix, Heroku
      </p>
      <br />
      <p>
        <span class="res-left">
          Almost there&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Python, Django & Drf, Flask, Java, Js, Nodejs, Debian, Redhat, Centos,
        Git,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Android App Development, Ionic, Postgresql, Mongodb, Firebase,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Scrapy, Celery, Redis, Rabbitmq, DO, Rest Api & Web services,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Php, Joomla, Wordpress, Photoshop, Gimp, Webpack, Grunt, Gulp
      </p>
      <br />
      <p>
        <span class="res-left">Actively Learning</span>Angularjs, Typescript, c,
        c++, Kernel Development, AWS
      </p>
      <br />
      <p>
        <span class="res-left">
          Beginner&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Reactjs, Spring, Jenkins, Socketio, Aosp Development
      </p>
      <br />
      <p>
        <span class="res-left">Curios to learn&nbsp;&nbsp;&nbsp;&nbsp;</span>AI,
        Machine Leanring, Deep Learning
      </p>
      <br />
      <p>
        <strong>Education</strong>
      </p>
      <br />
      <p>Bachelor of Technology | Biotechnology | 2007 - 2011</p>
      <p>Dropout | 2010</p>
      <br />
      <p>
        <strong>Experience</strong>
      </p>
      <br />
      <p>Freelance design and developer | 2012 - Present</p>
      <p>Web | Mobile | Cloud | Bigdata</p>
      <br />
      <p>
        <strong>Recent Projects</strong>
      </p>
      <br />
      <p>
        <a
          href="http://thequbits.com"
          target="_blank"
          rel="noopener noreferrer"
          title="The Qubits"
        >
          thequbits.com
        </a>{" "}
        | Website & Android App | Deployed [w/o Crawling & Scheduling]
      </p>
      <p>
        # Site for getting latest information related to Science, Technology &
        Space
      </p>
      <p>
        # Automatically schedules and crawls articles from other websites and
        publishes it
      </p>
      <p>
        - Tech - Python, Django & Drf, Scrapy, Celery, Html5, Css3, Js, Jquery,
        Typescript, Angularjs,
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rest
        Api, Json, Java, Android Programming, Redis, Ngnix, Sqlite, Postgresql
      </p>
      <p>- Deploy - Heroku, AWS S3 & RDS, Digital Ocean, Ubuntu</p>
      <br />
      <p>
        <a
          href="http://runsprin.com"
          target="_blank"
          rel="noopener noreferrer"
          title="RunSprin"
        >
          runsprin.com
        </a>{" "}
        | Website & Android App | in Backend Development
      </p>
      <p># Site for connecting people who are interested in running</p>
      <p>
        # User functionalities with login, profile, groups, feeds, maps, tagging
        & etc
      </p>
      <p>
        - Tech - Python, Django & Drf, Html5, Css3, Js, Jquery, Reactjs,
        Postgresql
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rest
        Api, Json, Java, Android Programming, Redis, Ngnix, Sqlite
      </p>
      <p>- Deploy - Heroku, AWS S3 & RDS, Digital Ocean, Ubuntu</p>
      <br />
    </div>
    <UpdateShare updated="18 Aug 2016" postTitle="Resume" />
  </PageWrapper>
);

export default ResumeAug;
