import React from 'react';
import './Footer.scss'

const Footer = ({ bg, clr }) => (
    <div className="nd-footer" style={{ background: bg }}>
        <div className="nd-f-icons">
            <a style={{ color: clr }} href="https://facebook.com/chaalpritam" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-facebook-f"></i>
            </a>
            <a style={{ color: clr }} href="https://twitter.com/chaalpritam" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-twitter"></i>
            </a>
            <a style={{ color: clr }} href="https://instagram.com/chaalpritam" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-instagram"></i>
            </a>
        </div>
    </div>
);

export default Footer;