import React, { Component } from "react";
import { LogoLayout, Grid2Layout, Grid3Layout, Grid4Layout, IllustrationLayout, Grid1Layout, LandingPageLayout } from "./FilterContentLayout";
import { QubitsLogo, HashHealLogo, MindSpeakAILogo, HashHealBanner, QubitsBanner, ThrottlersBanner, ChennaiCentral600, FalconRocket600, RiderGirl600, SukhoiSu30MKI600 } from "./Images";
import "./Work.scss";
import { FiltersButton, Nav, SocialNavi } from "./common";

const status = [
  { title: "All" },
  { title: "Mobile App Dev" },
  { title: "Frontend Dev" },
  { title: "Backend Dev" },
  { title: "Dashboard" },
  { title: "Landing Page" },
  { title: "API's" },
  { title: "UI Design" },
  { title: "Illustration" },
  { title: "Logo Design" },
  { title: "Hobby Dev" }
];

class Work extends Component {
  state = {
    filter: "All"
  };

  selectFilter = title => this.setState({ filter: title });

  render() {
    const { filter } = this.state;
    return (
      <div className="cp-work">
        <div className="cp-header-nav">
          <SocialNavi />
          <Nav />
        </div>
        <div className="cp-header-filters">
          {status.map((s, index) => (
            <FiltersButton
              key={index}
              title={s.title}
              selectFilter={this.selectFilter}
            />
          ))}
        </div>
        <div className="cp-filter-content">
          {filter}
          {filter === "All" && (
            <Grid3Layout>
              <LogoLayout title="Qubits" image={QubitsBanner} />
              <LogoLayout title="Throttlers" image={ThrottlersBanner} />
              <LogoLayout title="HashHeal" image={HashHealBanner} />
            </Grid3Layout>
          )}
          {filter === "Mobile App Dev" && (
            <p>This Component on Development :(</p>
          )}
          {filter === "Frontend Dev" && (
            <p>This Component on Development :(</p>
          )}
          {filter === "Backend Dev" && (
            <p>This Component on Development :(</p>
          )}
          {filter === "Dashboard" && (
            <p>This Component on Development :(</p>
          )}
          {filter === "Landing Page" && (
            <Grid1Layout>
              <LandingPageLayout title="Qubits" image={QubitsBanner} />
              <LandingPageLayout title="HashHeal" image={HashHealBanner} />
            </Grid1Layout>
          )}
          {filter === "API's" && (
            <p>This Component on Development :(</p>
          )}
          {filter === "UI Design" && (
            <p>This Component on Development :(</p>
          )}
          {filter === "Illustration" && (
            <Grid2Layout>
              <IllustrationLayout title="Falcon Heavy" image={FalconRocket600} />
              <IllustrationLayout title="Sukhoi SU-30MKI" image={SukhoiSu30MKI600} />
              <IllustrationLayout title="Chennai Central" image={ChennaiCentral600} />
              <IllustrationLayout title="Rider Girl" image={RiderGirl600} />
            </Grid2Layout>
          )}
          {filter === "Logo Design" && (
            <Grid4Layout>
            <LogoLayout title="Qubits" image={QubitsLogo} />
            <LogoLayout title="HashHeal" image={HashHealLogo} />
            <LogoLayout title="MindSpeak" image={MindSpeakAILogo} />
            {/* <LogoLayout title="Binocular" image={BinocularLogo} /> */}
          </Grid4Layout>
          )}
          {filter === "Hobby Dev" && (
            <p>This Component on Development :(</p>
          )}
        </div>
      </div>
    );
  }
}

export default Work;
