import React from "react";
import UpdateShare from "../Common/UpdateShare";
import PageWrapper from "./PageWrapper";
import { nav } from "./data";

const ResumeDec = () => (
  <PageWrapper
    title="Chaal's Blog > Resume"
    nav={nav.slice(0, 2)}
    cName="Chaal Pritam"
    cYear="2016"
    cLink="/"
    pTitle="Flask"
    pLink="http://flask.pocoo.org/"
    pAText="Flask | Python Microframework"
    hTitle="Heroku"
    hLink="http://heroku.com"
    hAText="Heroku"
  >
    <div class="inner cover post post-body resume">
      <p>
        <strong>Ezhumalai M</strong>{" "}
        <span class="res-right">
          <a href="mailto:chaalpritam@gmail.com">chaalpritam@gmail.com</a>
        </span>
      </p>
      <p>
        aka{" "}
        <a
          href="https://angel.co/chaalpritam"
          target="_blank"
          rel="noopener noreferrer"
          title="Angel"
        >
          @chaalpritam
        </a>
        <span class="res-right">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+91 7598 2190 77
        </span>
      </p>
      <br />
      <p>{`{Self-taught, Curious to learn, Loves to code, Passionate techy, Develop's ideas into products}`}</p>
      <br />
      <p>
        <strong>Skills</strong>
      </p>
      <br />
      <p>
        <span class="res-left">
          Strong&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Html5, Css3, Bootstrap, JQuery, Ubuntu, XML, Json, Php, Composer,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        REST API, GraphQL, Hadoop & Related packages, OpenStack Cloud,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Markdown, Git, Nginx, AWS, Heroku, DO, Docker, MySql, PostgreSQL,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        MongoDB, Sqlite, Firebase, Python, Django & DRF, Flask, Scrappy,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Java, Android App Development, Retrofit2, Rxjava2, Realm, Dagger2,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Glide, ButterKnife, OkHttp3, MVC, MVP, MVVM, Picasso, EventBus,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        GreenDAO, Robolectric, Espresso, Mockito, Hamcrest, Apollo-Android,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        JS ES6, NodeJS, ExpressJS, ReactJS, Redux, Apollo-React, VueJS, EJS,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        PassportJS, BookshelfJS, JsonWebToken, SequelizeJS, Eslint, Webpack,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        React Router, React Redux Router, Redux-thunk, Formik, SemanticUI
      </p>

      <br />
      <p>
        <span class="res-left">Knowledgeable&nbsp;&nbsp;</span>Celery, Redis,
        Rabbitmq, Typescript, AngularJS, Debian, Redhat, CentOS,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        SailsJs, VueX, Vue Router, SocketIO, Knex, Babel, RxJS, Axios, MobX,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Ionic, Joomla, Wordpress, Photoshop, Gimp, Kotlin, Mocha, Chai, Jest
      </p>

      <br />
      <p>
        <span class="res-left">Actively Learning</span>Blockchain, Ethereum,
        IOTA
      </p>
      <br />
      <p>
        <span class="res-left">Curious to learn&nbsp;&nbsp;&nbsp;</span>AI,
        Machine Learning, Deep Learning, AOSP & Kernel Development
      </p>
      <br />
      <p>
        <strong>Education</strong>
      </p>
      <br />
      <p>Bachelor of Technology | Biotechnology [ 2007 - 2012 ]</p>
      <p>Dropout | 2010</p>
      <br />
      <p>
        <strong>Work History</strong>
      </p>
      <br />
      <p>{`Freelance - {Web | Mobile | Cloud | Bigdata}`}</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + Web Design & Development &nbsp;&nbsp; [ Sep,
        2011 - Present ]
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + Php Development &nbsp;&nbsp; [ May, 2013 -
        Feb, 2016 ]
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + OpenStack Cloud Development &nbsp;&nbsp; [
        Feb, 2014 - July, 2016 ]
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + BigData & Hadoop Development &nbsp;&nbsp; [
        Dec, 2014 - April, 2016 ]
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + Android App Development &nbsp;&nbsp; [ Nov,
        2015 - Present ]
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + Python & Django Development &nbsp;&nbsp; [
        Dec, 2015 - Present ]
      </p>
      <br />

      <p>Other</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + NodeJs & ReactJs Development &nbsp;&nbsp; [
        Aug, 2017 - Present ]
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + GraphQL(React & Android) Development
        &nbsp;&nbsp; [ Sep, 2017 - Present ]
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + Blockchain & IOTA Development &nbsp;&nbsp; [
        Dec, 2017 - Present ]
      </p>
      <br />

      <p>Mentor - @ Smart India Hackathon 2017</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + For Team TechFire on solving the{" "}
        <a
          href="https://innovate.mygov.in/challenges/one-point-student-verification-through-mobile-application/"
          target="_blank"
          rel="noopener noreferrer"
          title="Problem"
          class="underline"
        >
          problem
        </a>{" "}
        with this{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.chaalpritam.apps.techfireopsv"
          target="_blank"
          rel="noopener noreferrer"
          title="Solution"
          class="underline"
        >
          solution
        </a>
        .
      </p>
      <br />
      <p>
        <strong>Recent Projects</strong>
      </p>
      <br />
      <p>Qubits</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; &#8226; Status -> Jan, 2016 - Dec, 2016 |
        Re-Launching March, 2018
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; +{" "}
        <a
          href="http://thequbits.herokuapp.com"
          target="_blank"
          rel="noopener noreferrer"
          title="The Qubits Website"
          class="underline"
        >
          Website
        </a>{" "}
        | Deployed on Heroku [w/o Crawling & Scheduling]
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Python, Django & DRF, Scrappy, Celery, Html5, Css3, Js, Jquery, Json,
        Rest API,
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Redis, Nginx, SQLite, Postgresql, Heroku, AWS S3 & RDS, Ubuntu
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; +{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.chaalpritam.apps.thequbitsshort"
          target="_blank"
          rel="noopener noreferrer"
          title="The Qubits Android App"
          class="underline"
        >
          Android App
        </a>{" "}
        | Published - Beta version
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Retrofit2, Rxjava2, Realm, Dagger2, Glide, ButterKnife, OkHttp3, MVC
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; # Site for getting latest information related
        to Science, Technology & Space.
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; # Automatically schedules and crawls articles
        from other websites and publishes it.
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; ~ Todo -> Frontend to React & App with UI, MVVM
        and Kotlin.
      </p>
      <br />
      <p>Throttlers</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; &#8226; Status -> Oct, 2017 - Present |
        Launching October, 2018
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; +{" "}
        <a
          href="http://throttlers.in"
          target="_blank"
          rel="noopener noreferrer"
          title="The Throttlers Website"
          class="underline"
        >
          Website
        </a>{" "}
        | Deployed on Heroku - beta
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        JavaScript ES6, GraphQL, ReactJs, Redux, ExpressJS, Postgresql,
        SequelizeJS,
      </p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp; + Android App | Launching Jan, 2019</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Kotlin, Apollo-Android, MVVM, OkHtttp3, Glide, Rxjava2, ButterKnife
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; # Site for motorcyclist, riders and travellers
        with social networking and marketplace.
      </p>
      <br />
      <p>BooksReadBy</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; &#8226; Status -> April, 2017 - Present |
        Active
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; +{" "}
        <a
          href="http://booksreadby.com"
          target="_blank"
          rel="noopener noreferrer"
          title="BooksReadBy Website"
          class="underline"
        >
          Website
        </a>{" "}
        | Deployed on Heroku
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Python, Django & DRF, Html5, Css3, Js, Jquery, Json, Rest API,
        Postgresql,
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Heroku, Gunicorn, SQLite, Postgresql, AWS S3 & RDS, Ubuntu
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; +{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.chaalpritam.apps.booksreadby"
          target="_blank"
          rel="noopener noreferrer"
          title="BooksReadBy Android App"
          class="underline"
        >
          Android App
        </a>{" "}
        | Published
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Retrofit2, Rxjava2, Realm, Dagger2, Glide, ButterKnife, OkHttp3, MVC
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; # BooksReadBy provides a curated list of books
        that read by famous personalities.
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; ~ Todo -> Frontend to React & App with UI, MVVM
        and Kotlin.
      </p>
      <br />
      <p>Binocular</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; &#8226; Status -> Oct, 2016 - July, 2016 |
        discontinued
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; +{" "}
        <a
          href="http://binoculardev.herokuapp.com"
          target="_blank"
          rel="noopener noreferrer"
          title="Binocular Website"
          class="underline"
        >
          Website
        </a>{" "}
        | Deployed on Heroku
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Python, Django & DRF, Html5, Css3, Js, Jquery, Json, Rest API,
        Postgresql,
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Heroku, Gunicorn, SQLite, Postgresql, AWS S3 & RDS, Ubuntu
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; +{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.chaalpritam.apps.binocular"
          target="_blank"
          rel="noopener noreferrer"
          title="Binocular Android App"
          class="underline"
        >
          Android App
        </a>{" "}
        | Published - Beta version
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Retrofit2, Rxjava2, Realm, Dagger2, Glide, ButterKnife, OkHttp3, MVC
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Robolectric, Espresso, Mockito, Hamcrest
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; # Binocular enables people to find favourite
        foods around their locality.
      </p>
      <br />
    </div>
    <UpdateShare updated="20 Dec 2017" postTitle="Resume" />
  </PageWrapper>
);

export default ResumeDec;
