import React from "react";
import UpdateShare from "../Common/UpdateShare";
import PageWrapper from "./PageWrapper";
import { nav } from "./data";

const ResumeMay = () => (
  <PageWrapper
    title="Chaal's Blog > Resume"
    nav={nav.slice(0, 2)}
    cName="Chaal Pritam"
    cYear="2016"
    cLink="/"
    pTitle="Flask"
    pLink="http://flask.pocoo.org/"
    pAText="Flask | Python Microframework"
    hTitle="Heroku"
    hLink="http://heroku.com"
    hAText="Heroku"
  >
    <div class="inner cover post post-body resume">
      <p>
        <strong>Ezhumalai M</strong>{" "}
        <span class="res-right">
          <a href="mailto:chaalpritam@gmail.com">chaalpritam@gmail.com</a>
        </span>
      </p>
      <p>
        aka{" "}
        <a
          href="http://twitter.com/chaalpritam"
          target="_blank"
          rel="noopener noreferrer"
          title="Twitter"
        >
          @chaalpritam
        </a>
        <span class="res-right">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+91 7598 2190 77
        </span>
      </p>
      <br />
      <p>{`{Self-taught, Curious to learn, Loves to code, Passionate techy, Develop's ideas into products}`}</p>
      <br />
      <p>
        <strong>Skills</strong>
      </p>
      <br />
      <p>
        <span class="res-left">
          Strong&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Html5, Css3, Bootstrap, Jquery, OpenStack, Hadoop & Related packages,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Ubuntu, XML, Json, Mysql, Sqlite, Markdown, Nginx, Heroku, Python, Git,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Django & DRF, Flask, Java, Js, Android App Development, Firebase, DO,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Postgresql, Php, Composer, Rest API & Web services, MongoDB, AWS,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Retrofit2, Rxjava2, Realm, Dagger2, Glide, ButterKnife, OkHttp3, MVC,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Picasso, EventBus, GreenDAO, Robolectric, Espresso, Mockito, Hamcrest
      </p>

      <br />
      <p>
        <span class="res-left">Knowledgeable&nbsp;&nbsp;&nbsp;</span>NodeJS,
        AngularJS, Typescript, Debian, Redhat, CentOS, Ionic, Scrappy,
      </p>
      <p>
        <span class="res-left">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>{" "}
        Celery, Redis, Rabbitmq, Joomla, Wordpress, Photoshop, Gimp, Webpack
      </p>
      <br />
      <p>
        <span class="res-left">Actively Learning</span>Kotlin, ReactJS
      </p>
      <br />
      <p>
        <span class="res-left">Curious to learn&nbsp;&nbsp;&nbsp;&nbsp;</span>
        AI, Machine Learning, Deep Learning, AOSP & Kernel Development
      </p>
      <br />
      <p>
        <strong>Education</strong>
      </p>
      <br />
      <p>Bachelor of Technology | Biotechnology [ 2007 - 2012 ]</p>
      <p>Dropout | 2010</p>
      <br />
      <p>
        <strong>Work History</strong>
      </p>
      <br />
      <p>{`Freelance - {Web | Mobile | Cloud | Bigdata}`}</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + Web Design & Development &nbsp;&nbsp; [ Sep,
        2011 - Present ]
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + Php Development &nbsp;&nbsp; [ May, 2013 -
        Feb, 2016 ]
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + OpenStack Cloud Development &nbsp;&nbsp; [
        Feb, 2014 - July, 2016 ]
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + BigData & Hadoop Development &nbsp;&nbsp; [
        Dec, 2014 - April, 2016 ]
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + Android App Development &nbsp;&nbsp; [ Nov,
        2015 - Present ]
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + Python & Django Development &nbsp;&nbsp; [
        Dec, 2015 - Present ]
      </p>
      <br />

      <p>Mentor - @ Smart India Hackathon 2017</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; + For Team TechFire on solving the{" "}
        <a
          href="https://innovate.mygov.in/challenges/one-point-student-verification-through-mobile-application/"
          target="_blank"
          rel="noopener noreferrer"
          title="Problem"
          class="underline"
        >
          problem
        </a>{" "}
        with this{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.chaalpritam.apps.techfireopsv"
          target="_blank"
          rel="noopener noreferrer"
          title="Solution"
          class="underline"
        >
          solution
        </a>
        .
      </p>
      <br />
      <p>
        <strong>Recent Projects</strong>
      </p>
      <br />
      <p>thequbits</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; +{" "}
        <a
          href="http://thequbits.herokuapp.com"
          target="_blank"
          rel="noopener noreferrer"
          title="The Qubits Website"
          class="underline"
        >
          Website
        </a>{" "}
        | Deployed on Heroku [w/o Crawling & Scheduling] | Launching this June
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Python, Django & DRF, Scrappy, Celery, Html5, Css3, Js, Jquery, Json,
        Rest API,
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Redis, Nginx, SQLite, Postgresql, Heroku, AWS S3 & RDS, Ubuntu
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; +{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.chaalpritam.apps.thequbitsshort"
          target="_blank"
          rel="noopener noreferrer"
          title="The Qubits Android App"
          class="underline"
        >
          Android App
        </a>{" "}
        | Published - Beta version
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Retrofit2, Rxjava2, Realm, Dagger2, Glide, ButterKnife, OkHttp3, MVC
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; # Site for getting latest information related
        to Science, Technology & Space.
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; # Automatically schedules and crawls articles
        from other websites and publishes it.
      </p>
      <br />
      <p>Binocular</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; +{" "}
        <a
          href="http://thebinocular.in"
          target="_blank"
          rel="noopener noreferrer"
          title="Binocular Website"
          class="underline"
        >
          Website
        </a>{" "}
        | Deployed on Heroku | Launching this July
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Python, Django & DRF, Html5, Css3, Js, Jquery, Json, Rest API,
        Postgresql,
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Heroku, Gunicorn, SQLite, Postgresql, AWS S3 & RDS, Ubuntu
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; +{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.chaalpritam.apps.binocular"
          target="_blank"
          rel="noopener noreferrer"
          title="Binocular Android App"
          class="underline"
        >
          Android App
        </a>{" "}
        | Published - Beta version
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Retrofit2, Rxjava2, Realm, Dagger2, Glide, ButterKnife, OkHttp3, MVC
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Robolectric, Espresso, Mockito, Hamcrest
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; # Binocular enables people to find favourite
        foods around their locality.
      </p>
      <br />
      <p>BooksReadBy</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; +{" "}
        <a
          href="http://booksreadby.com"
          target="_blank"
          rel="noopener noreferrer"
          title="BooksReadBy Website"
          class="underline"
        >
          Website
        </a>{" "}
        | Deployed on Heroku
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Python, Django & DRF, Html5, Css3, Js, Jquery, Json, Rest API,
        Postgresql,
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Heroku, Gunicorn, SQLite, Postgresql, AWS S3 & RDS, Ubuntu
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; +{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.chaalpritam.apps.booksreadby"
          target="_blank"
          rel="noopener noreferrer"
          title="BooksReadBy Android App"
          class="underline"
        >
          Android App
        </a>{" "}
        | Published
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Retrofit2, Rxjava2, Realm, Dagger2, Glide, ButterKnife, OkHttp3, MVC
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp; # BooksReadBy provides a curated list of books
        that read by famous personalities.
      </p>
      <br />
    </div>
    <UpdateShare updated="11 May 2017" postTitle="Resume" />
  </PageWrapper>
);

export default ResumeMay;
