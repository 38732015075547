import React, { useContext } from 'react';
import './index.scss';
import Header from './Header';
import Footer from './Footer';
import { Menu } from './Common';
import { ThemeProvider, ThemeContext } from './ThemeContext';
import { Switch, Route } from 'react-router-dom';
import Resume from './Resume';
import Resume20 from './Resume20';
import Resume21 from './Resume21';
import Resume22 from './Resume22';
import Resume23 from './Resume23';
// import { PostListCard } from './Common';

const Home = () => {
    const { themeBg, themeTxt, bodyBg, bodyTxt } = useContext(ThemeContext);
    return (
    <div style={{ background: bodyBg, color: bodyTxt }}>
        <Header bg={themeBg} clr={themeTxt} />
        <div className="nd-posts">
            <h3>Latest Posts</h3>
            {/* <PostListCard 
                title="Understanding Hooks in JS"
            /> */}
            <p>No posts</p>
        </div>
        <Footer bg={themeBg} clr={themeTxt} />
    </div>
    )
};

const Blog = () => {
    const { themeBg, themeTxt, bodyBg, bodyTxt } = useContext(ThemeContext);
    return (
        <div className="nd-blog" style={{ background: themeBg, color: themeTxt }}>
            <Menu />
            <div className="nd-b-posts" style={{ background: bodyBg, color: bodyTxt }}>
                <div className="nd-posts">
                    <h3>All Posts</h3>
                    {/* <PostListCard 
                        title="Understanding Hooks in JS"
                    /> */}
                    <p>No posts</p>
                </div>
            </div>
            <Footer bg={themeBg} clr={themeTxt} />
        </div>
    )
};

export const NewDesign = () => {
    return (
        <ThemeProvider>
            <div className="nd">
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/blog" component={Blog} />
                    <Route exact path="/resume" component={Resume} />
                    <Route exact path="/resume20" component={Resume20} />
                    <Route exact path="/resume21" component={Resume21} />
                    <Route exact path="/resume22" component={Resume22} />
                    <Route exact path="/resume23" component={Resume23} />
                </Switch>
            </div>
        </ThemeProvider>
    );
}
