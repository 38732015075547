import React from "react";
import Footer from "../Common/Footer";
import PageHeader from "../Common/PageHeader";

const PageWrapper = ({
  title,
  nav,
  postTitle,
  updated,
  children,
  cName,
  cYear,
  cLink,
  pTitle,
  pLink,
  pAText,
  hTitle,
  hLink,
  hAText
}) => (
  <div className="post-template page-template page">
    <div className="container">
      <div className="site-wrapper">
        <div className="site-wrapper-inner">
          <div className="cover-container">
            <PageHeader title={title} navs={nav} />
            {children}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Footer
            cName={cName}
            cYear={cYear}
            cLink={cLink}
            pTitle={pTitle}
            pLink={pLink}
            pAText={pAText}
            hTitle={hTitle}
            hLink={hLink}
            hAText={hAText}
          />
        </div>
      </div>
    </div>
  </div>
);

export default PageWrapper;
