import React from "react";
import { QubitsBanner, HashHealBanner, ThrottlersBanner } from "./Images";
import HomeMain from "./HomeMain";
import Project from "./Project";

const Home = () => (
  <>
    <HomeMain />
    <Project
      title="Qubits"
      titleColor="#3F989A"
      description="Mobile app for getting latest information related
        to Science, Technology & Space."
      status={[
        { title: "Landing Page" },
        { title: "Mobile App" },
        { title: "UI Design" },
        { title: "Front-End" },
        { title: "Back-End" },
        { title: "Product Strategy" }
      ]}
      banner={QubitsBanner}
      bg="#3F414C"
    />
    <Project
      title="Throttlers"
      titleColor="#DBA026"
      description="Site for motorcyclist, riders and travellers with social networking and marketplace."
      status={[
        { title: "Landing Page" },
        { title: "Mobile App" },
        { title: "Website" },
        { title: "Front-End" },
        { title: "Back-End" }
      ]}
      banner={ThrottlersBanner}
      bg="#303030"
    />
    <Project
      title="HashHeal"
      titleColor="#718FCC"
      description="Blockchain based analytics dashboard tool for all kind of activity trackers."
      status={[
        { title: "Landing Page" },
        { title: "Dashboard" },
        { title: "UI Design" },
        { title: "Front-End" },
        { title: "Back-End" }
      ]}
      banner={HashHealBanner}
      bg="#3F414C"
    />
  </>
);

export default Home;
