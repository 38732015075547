import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import Work from "./Work";

const Design = () => (
  <Switch>
    <Route exact path="/old" component={Home} />
    <Route exact path="/old/work" component={Work} />
  </Switch>
);

export default Design;
