import React from "react";
import {SwitchButton} from "./Images";
import "./common.scss";

export const Nav = () => (
  <div className="cp-nav">
    <ul>
      <li>
        <a href="/old/about">about</a>
      </li>
      <li>
        <a href="/old/work">work</a>
      </li>
      <li>
        <a href="/old/blog" target="_blank" rel="noopener noreferrer">
          blog
        </a>
      </li>
      <li>
        <a href="mailto:chaalpritam@gmail.com" target="_top">
          contact
        </a>
      </li>
      <li>
        <a href="/old">
          <img src={SwitchButton} alt="Home" />
        </a>
      </li>
    </ul>
  </div>
);

export const SocialNavi = () => (
  <div className="cp-social-navi">
    <ul>
      <li>
        <a
          href="https://www.facebook.com/chaal.pritam"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-facebook" />
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/chaalpritam/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-instagram" />
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/chaalpritam"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-twitter" />
        </a>
      </li>
    </ul>
  </div>
);

export const SocialNav = () => (
  <div className="cp-social-nav">
    <ul>
      <li>
        <a
          href="https://www.facebook.com/chaal.pritam"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/chaalpritam/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/chaalpritam"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>
      </li>
    </ul>
  </div>
);

export const BgText = () => (
  <div className="cp-bg-text">
    <p>
      Think
      <br />
      &nbsp;&nbsp;&nbsp;Design
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Code
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Learn
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Share
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sleep
    </p>
  </div>
);

export const ProjList = () => (
  <div className="cp-proj-list">
    <ul>
      <li>Qubits</li>
      <li>Throttlers</li>
      <li>HashHeal</li>
    </ul>
  </div>
);

export const ProjStatusButton = ({ title }) => (
  <div className="cp-proj-status-button">{title}</div>
);

export const FiltersButton = ({ title, selectFilter }) => (
  <div className="cp-filters-button" onClick={() => selectFilter(title)}>
    {title}
  </div>
);
