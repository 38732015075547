import React from 'react';
import './Resume.scss';

const Resume21 = () => {
    return (
        <div className="nd-resume">
            <div className="nd-r-nav">
                <p><a href="/">Chaal's</a> `{'>'}` Resume</p>
                <p><a href="https://github.com/chaalpritam" target="_blank" rel="noopener noreferrer">Github</a></p>
            </div>
            <div className="nd-r-header">
                <div className="nd-rh">
                    <div className="nd-rh-l">
                        <h3>Ezhumalai M</h3>
                        <p>aka <a href="https://angel.co/u/chaalpritam" target="_blank" rel="noopener noreferrer">@chaalpritam</a></p>
                    </div>
                    <div className="nd-rh-r">
                        <p>chaalpritam@gmail.com</p>
                        <p>+91 7598 2190 77</p>
                    </div>
                </div>
                <p>{"{ Self-taught developer, Curious to learn, Love to code, Design Develop & Build idea into product }"}</p>
            </div>
            <div className="nd-r-edu">
                <h3>Education</h3>
                <p>Bachelor of Technology | Biotechnology [ 2007 - 2012 ]</p>
                <p>Dropout | 2010</p>
            </div>
            <div className="nd-r-dev">
                <h3>Dev History</h3>
                <p>&nbsp;&nbsp; + Web Design & Development    [ Sep, 2011 - Present ]</p>
                <p>&nbsp;&nbsp; <s>+ Php Development    [ May, 2013 - Feb, 2016 ]</s></p>
                <p>&nbsp;&nbsp; <s>+ OpenStack Cloud Development    [ Feb, 2014 - July, 2016 ]</s></p>
                <p>&nbsp;&nbsp; <s>+ BigData & Hadoop Development    [ Dec, 2014 - April, 2016 ]</s></p>
                <p>&nbsp;&nbsp; <s>+ Android App Development    [ Nov, 2015 - Feb, 2018 ]</s></p>
                <p>&nbsp;&nbsp; <s>+ Python & Django Development    [ Dec, 2015 - Jan, 2018 ]</s></p>
                <p>&nbsp;&nbsp; + Node & ReactJs & GraphQL Development    [ Aug, 2017 - Present ]</p>
                <p>&nbsp;&nbsp; + React Native Development    [ July, 2018 - Present ]</p>
                <p>&nbsp;&nbsp; <s>+ GoLang Development    [ Dec, 2018 - June, 2020 ]</s></p>
                <p>&nbsp;&nbsp; + Typescript Development    [ Jan, 2019 - Present ]</p>
                <p>&nbsp;&nbsp; + Blockchain & Ethereum Development    [ Aug, 2019 - Present ]</p>
            </div>
            <div className="nd-r-work">
                <h3>Recent Work</h3>
                <p>~ Full Stack JS Engineer - <a href="https://angel.co/company/fanlytiks" target="_blank" rel="noopener noreferrer">@Fanlytiks</a> [ Feb, 2018 ]</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp; # Built the Analytics Dashboard's front-end and back-end architecture from scratch</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp; + FrontEnd `{'->'}` React, Redux, Mobx, Mobx-State-Tree, GraphQL(Apollo GraphQL)</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp; + BackEnd `{'->'}` Express, Sequelize, GraphQL(Apollo GraphQL), Postgres, Redshift, Redis</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp; + Other `{'->'}` Facebook & Twitter API Integration, Maintaining Android Library</p>
                <p>~ Blockchain Engineer | <u>Remote</u> - <a href="https://angel.co/company/fanlytiks" target="_blank" rel="noopener noreferrer">@Fanlytiks</a> [ Aug, 2019 - Mar, 2020 ]</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp; # Built the blockchain analytics platform with support for mobile applications</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp; + Hyperledger Iroha & Integration with it's mobile libraries and dashboard</p>
                <p>~ Full Stack TS Engineer | <u>Remote</u> - <a href="https://angel.co/company/credilio" target="_blank" rel="noopener noreferrer">@Credilio</a> [ Aug, 2020 - Dec, 2020 ]</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp; # Built the fintech platform's backend API and Integration with HDFC bank API's</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp; + TypeScript, Adonis 5, REST API, VUE 3, Swagger, Postgres, Redis, AWS S3, Docker</p>
            </div>
            <div className="nd-r-proj">
                <h3>Recent Projects</h3>
                <p>~ <a href="https://twitter.com/circlesfinance" target="_blank" rel="noopener noreferrer">Circles Finance</a> ~ | <b>Top 8 in Binance #BuildForBharath Hackathon (Among 350+ Startups)</b></p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp; • Status `{'->'}` Sep, 2020 | Launching Sep, 2021</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp; + Dashboard </p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; React, GraphQL, Ethereum SmartContract, Solidity, Truffle, AWS, Binance Smart Chain (BSC)</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp; # DeFi lending platform without collateral.</p>
                {/* <p>~ BlockSpace ~</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp; • Status `{'->'}` Feb, 2021 | Launching Jan, 2022</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp; + Dashboard, Android App</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; React, React Native, GraphQL, REST, TypeScript, AWS, SmartContract, Solidity, Truffle, BSC</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp; # DeFi app for file storage majorly photos (like Google Photos)</p> */}
            </div>
            <div className="nd-r-footer">
                <div className="nd-rf-l">
                    <p>Last Updated: 23 Feb 2021</p>
                </div>
                <div className="nd-rf-r">
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${
                        window.location.href
                        }`}
                        onclick="window.open(this.href, 'facebook-share', 'width=580,height=296');return false;"
                    >
                        <i class="fa fa-facebook" />
                    </a>
                    <a href={`http://twitter.com/share?text=Chaal's Resume&amp;url=${
                        window.location.href
                        }`}
                        onclick="window.open(this.href, 'twitter-share', 'width=550,height=235');return false;"
                    >
                        <i className="fa fa-twitter" />
                    </a>
                </div>
            </div>
        </div>
    )
};

export default Resume21;