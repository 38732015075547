import React, { Fragment } from "react";

const Footer = ({
  cName,
  cYear,
  cLink,
  pTitle,
  pLink,
  pAText,
  hTitle,
  hLink,
  hAText
}) => (
  <Fragment>
    <footer className="site-footer">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-muted">
            <section class="copyright">
              All content copyright <a href={cLink | "/"}> {cName}</a> © {cYear}{" "}
              • All rights reserved.
            </section>
            <section class="poweredby">
              Powered by{" "}
              <a
                href={pLink}
                target="_blank"
                rel="noopener noreferrer"
                title={pAText}
              >
                {pTitle}
              </a>
            </section>
            <section className="poweredby">
              Hosted with love at{" "}
              <a
                href={hLink}
                target="_blank"
                rel="noopener noreferrer"
                title={hAText}
              >
                {hTitle}
              </a>
            </section>
          </div>
        </div>
      </div>
    </footer>
  </Fragment>
);

export default Footer;
