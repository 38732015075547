import React from "react";

const UnderDev = () => (
  <p>
    Site under development :( <br />{" "}
    <li>
      <a href="/old">Home</a>
    </li>
  </p>
);

export default UnderDev;
