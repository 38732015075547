import React, { Fragment } from "react";

const PageHeader = ({ title, navs }) => (
  <Fragment>
    <div className="masthead clearfix">
      <div className="inner">
        <h3 className="masthead-brand">{title}</h3>
        <ul className="nav masthead-nav">
          {navs &&
            navs.map(n => (
              <li className={`nav-${n.name}`} role="presentation">
                <a href={n.link} target={n.target} rel="noopener noreferrer">
                  {n.name}
                </a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  </Fragment>
);

export default PageHeader;
