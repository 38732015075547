import ChaalPritam_ from "./ChaalPritam.png";
import SwitchButton_ from "./SwitchButton.png";
import BinocularLogo_ from "./BinocularLogo.png";
import HashHealLogo_ from "./HashHealLogo.png";
import QubitsLogo_ from "./QubitsLogo.png";
import MindSpeakAILogo_ from "./MindSpeakAILogo.png";
import QubitsBanner_ from "./QubitsBanner.jpg";
import HashHealBanner_ from "./HashHealBanner.jpg";
import ThrottlersBanner_ from "./ThrottlersBanner.jpg";
import ChennaiCentral600_ from "./ChennaiCentral600.png";
import FalconRocket600_ from "./FalconRocket600.png";
import RiderGirl600_ from "./RiderGirl600.png";
import SukhoiSu30MKI600_ from "./SukhoiSu-30MKI600.png";

export const ChaalPritam = ChaalPritam_;
export const SwitchButton = SwitchButton_;
export const BinocularLogo = BinocularLogo_;
export const HashHealLogo = HashHealLogo_;
export const QubitsLogo = QubitsLogo_;
export const MindSpeakAILogo = MindSpeakAILogo_;
export const QubitsBanner = QubitsBanner_;
export const HashHealBanner = HashHealBanner_;
export const ThrottlersBanner = ThrottlersBanner_;
export const ChennaiCentral600 = ChennaiCentral600_;
export const FalconRocket600 = FalconRocket600_;
export const RiderGirl600 = RiderGirl600_;
export const SukhoiSu30MKI600 = SukhoiSu30MKI600_;

