import React from "react";
import "./Project.scss";
import { ProjStatusButton } from "./common";

const Project = ({ title, titleColor, description, status, banner, bg }) => (
  <div className="cp-project" style={{ backgroundColor: bg }}>
    <div className="cp-info">
      <h3 style={{ color: titleColor }}>{title}</h3>
      <p>{description}</p>
      {status.map((s, index) => (
        <ProjStatusButton key={index} title={s.title} />
      ))}
    </div>
    <div className="cp-banner">
      <img src={banner} alt="" />
    </div>
  </div>
);

export default Project;
