import React, { Fragment } from "react";

const SMLink = ({ title, link }) => (
  <Fragment>
    <a href={link} target="_blank" rel="noopener noreferrer" title={title}>
      {" "}
      {title}{" "}
    </a>
    |
  </Fragment>
);

const SocialMediaLinks = ({ sml }) => (
  <Fragment>
    <center>
      <h3 id="screen_nav">
        « {sml && sml.map(s => <SMLink title={s.title} link={s.link} />)} »
      </h3>
      <p />
    </center>
  </Fragment>
);

export default SocialMediaLinks;
