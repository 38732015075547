import React from "react";
import "./HomeMain.scss";
import { ChaalPritam } from "./Images";
import { BgText, Nav, ProjList, SocialNav } from "./common";

const HomeMain = () => (
  <div className="cp-home">
    <div
      className="cp-bg"
      style={{
        backgroundImage: `url(${ChaalPritam})`,
        backgroundPosition: "left",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }}
    >
      <div />
      <div className="cp-desc">
        <h3>chaalpritam</h3>
        <div className="cp-desi">
          <p>Fullstack Developer & UI/UX Designer</p>
        </div>
        <ProjList />
      </div>
    </div>
    <div className="cp-content">
      <Nav />
      <div className="cp-main-content">
        <BgText />
        <SocialNav />
      </div>
    </div>
  </div>
);

export default HomeMain;
