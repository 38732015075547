import React, { Fragment } from "react";

const PostTitle = ({ postTitle }) => (
  <Fragment>
    <div class="inner cover post post-head">
      <h1 class="cover-heading post-title">{postTitle}</h1>
    </div>
  </Fragment>
);

export default PostTitle;
