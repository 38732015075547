import React from 'react';
import './Header.scss';
import { Menu } from './Common';

const Header = ({ bg, clr }) => (
    <div className="nd-header" style={{ background: bg, color: clr }}>
        <Menu />
        <div className="nd-h-content">
            <div className="nd-h-c">
                {/* <h2>
                    Hey there <span role="img" aria-label="wave">👋</span> <br />
                    I'm <u><a style={{ color: clr }} href="https://twitter.com/chaalpritam" target="_blank" rel="noopener noreferrer">Chaal Pritam</a></u> a <br/>
                    full stack developer <br />
                </h2> */}
                <h2>
                    Hey there <span role="img" aria-label="wave">👋</span> <br />
                    I'm <u><a style={{ color: clr }} href="https://twitter.com/chaalpritam" target="_blank" rel="noopener noreferrer">Chaal Pritam</a></u> a <br/>
                    FullStack TS & Blockchain Engineer <br />
                </h2>
                {/* <p>I work with early-stage startups & passionate about building<br /> 
                Web, Mobile applications, Saas products, Cloud & Drone.<br /> 
                ~ Go & Javascript ~ React, GraphQL, Android, OpenStack</p> */}
                {/* <p>I work with early-stage startups & passionate about Designing<br /> 
                & Building Web3 products ~ DeFi | NFT | Metaverse | DAO<br /><br /> 
                ~ I also do UI/UX ~ Motion Graphics ~ 3D ~ VoxelArt ~<br /><br /> */}
                <p>I work with early-stage startups and am passionate about designing
                & building Web3 consumer products, on-chain games & DePIN<br /><br /> 
                ~ I also do UI/UX ~ Motion Graphics ~ 3D ~ VoxelArt ~<br /><br />
                ~ Actively building communities 
                &nbsp;<u><a style={{ color: clr }} href="https://twitter.com/Web3Chennai" target="_blank" rel="noopener noreferrer">Web3Chennai</a></u>
                &nbsp;&nbsp;&&nbsp;&nbsp;<u><a style={{ color: clr }} href="https://twitter.com/movetheblock_" target="_blank" rel="noopener noreferrer">MTB</a></u>
                <br /><br />
                Bootstrapping &nbsp;
                {/* &nbsp;<u><a style={{ color: clr }} href="https://twitter.com/Web3Chennai" target="_blank" rel="noopener noreferrer">Web3Chennai</a></u>
                &nbsp;&nbsp;<u><a style={{ color: clr }} href="https://twitter.com/movetheblock_" target="_blank" rel="noopener noreferrer">Move the Block</a></u> */}
                <u><a style={{ color: clr }} href="https://twitter.com/JezeroProtocol" target="_blank" rel="noopener noreferrer">Jezero Protocol</a></u>
                <br /><br /></p>
                <div className="nd-h-sm">
                    <h5><a style={{ color: clr }} href="mailto:me@chaalpritam.com" target="_blank" rel="noopener noreferrer">me@chaalpritam.com</a></h5>
                    <h5><a style={{ color: clr }} href="https://github.com/chaalpritam" target="_blank" rel="noopener noreferrer">github</a></h5>
                    <h5><a style={{ color: clr }} href="https://twitter.com/chaalpritam" target="_blank" rel="noopener noreferrer">twitter</a></h5>
                </div>
            </div>
            <div className="nd-h-dp">
                <img src="https://pbs.twimg.com/profile_images/1676815408287055872/CgaqyBZh_400x400.jpg" alt="Chaal Pritam twitter dp"/>
                {/* <img src="https://pbs.twimg.com/profile_images/1563671247476535296/7Lrk8lCe_400x400.jpg" alt="Chaal Pritam twitter dp"/> */}
                {/* <img src="https://pbs.twimg.com/profile_images/1513777578187931648/iPt4ghMG_400x400.jpg" alt="Chaal Pritam twitter dp"/> */}
                {/* <img src="https://pbs.twimg.com/profile_images/1007723531155542017/k_NVcEJR_400x400.jpg" alt="Chaal Pritam twitter dp"/> */}
            </div>
        </div>
    </div>
);

export default Header;