import React, { useContext } from 'react';
import { ThemeContext } from './ThemeContext';
import './Common.scss';

export const Menu = () => {
    const {theme, toggleTheme, themeTxt } = useContext(ThemeContext);
    return (
        <div className="nd-menu">
            <h1><a style={{ color: themeTxt }} href="/">ChaalPritam</a></h1>
            <ul>
                <li><a style={{ color: themeTxt }} href="/blog">Blog</a></li>
                <li><a style={{ color: themeTxt }} href="mailto:chaalpritam@gmail.com" target="_top">Contact</a></li>
                <label className="switch">
                    <input type="checkbox" onChange={toggleTheme} checked={theme === "light" ? false : true}/>
                    <span className="slider round"></span>
                </label>
            </ul>
        </div>
    )
};

export const PostListCard = ({ title }) => (
    <div className="nd-post-ls-card">
        <h4>{title}</h4>
        <div className="nd-pls-tags">
            <h6>Javascript</h6>
            <h6>React JS</h6>
            <h6>React Hooks</h6>
        </div>
    </div>
);

