export const nav = [
  {
    name: "Home",
    link: "/old"
  },
  {
    name: "About",
    link: "/old/about"
  },
  {
    name: "Tech",
    link: "http://chaalpritam.blogspot.com/",
    target: "_blank"
  },
  {
    name: "Blog",
    link: "/old/blog"
  }
];

export const sml = [
  {
    title: "Youtube",
    link: "https://www.youtube.com/user/chaalpritam"
  },
  {
    title: "Twitter",
    link: "https://twitter.com/chaalpritam"
  },
  {
    title: "Medium",
    link: "https://medium.com/@chaalpritam"
  },
  {
    title: "Github",
    link: "https://github.com/chaalpritam"
  },
  {
    title: "Angel",
    link: "https://angel.co/chaalpritam"
  },
  {
    title: "Behance",
    link: "https://www.behance.net/chaalpritam"
  },
  {
    title: "Resume",
    link: "/resume"
  }
];

export const dev = [
  {
    title: "Web Design & Development",
    startDate: "Sep, 2011",
    endDate: "Present"
  },
  {
    title: "Php Development",
    startDate: "May, 2013",
    endDate: "Feb, 2016"
  },
  {
    title: "OpenStack Cloud Development",
    startDate: "Feb, 2014",
    endDate: "July, 2016"
  },
  {
    title: "BigData & Hadoop Development",
    startDate: "Dec, 2014",
    endDate: "April, 2016"
  },
  {
    title: "Android App Development",
    startDate: "Nov, 2015",
    endDate: "Feb, 2018"
  },
  {
    title: "Python & Django Development",
    startDate: "Dec, 2015",
    endDate: "Jan, 2018"
  },
  {
    title: "Blockchain & Ethereum Development",
    startDate: "Dec, 2017",
    endDate: "Jan, 2018"
  },
  {
    title: "ElectronJs Development",
    startDate: "Aug, 2018",
    endDate: "Present"
  },
  {
    title: "NodeJs & ReactJs Development",
    startDate: "Aug, 2017",
    endDate: "Present"
  },
  {
    title: "GraphQL(React & Android) Development",
    startDate: "Sep, 2017",
    endDate: "Present"
  },
  {
    title: "React Native Development",
    startDate: "July, 2018",
    endDate: "Present"
  },
  {
    title: "GoLang Development",
    startDate: "Dec, 2018",
    endDate: "Present"
  },
  {
    title: "Typescript Development",
    startDate: "Dec, 2018",
    endDate: "Present"
  },
  {
    title: "Machine Learning & Deep Learning Development",
    startDate: "Dec, 2018",
    endDate: "Present"
  }
];
