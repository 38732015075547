import React, { Fragment } from "react";

export const RHeader = () => (
  <Fragment>
    <p>
      <strong>Ezhumalai M</strong>
      <span class="res-right">
        <a href="mailto:chaalpritam@gmail.com">chaalpritam@gmail.com</a>
      </span>
    </p>
    <p>
      aka{" "}
      <a
        href="https://angel.co/chaalpritam"
        target="_blank"
        rel="noopener noreferrer"
        title="Chaal Pritam - Angel"
      >
        @chaalpritam
      </a>
      <span class="res-right">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+91 7598 2190 77
      </span>
    </p>
    <br />
    <p>{`{Self-taught, Curious to learn, Loves to code, Passionate techy, Develop's ideas into products}`}</p>
    <br />
  </Fragment>
);

export const RTitleWrapper = ({ title, children }) => (
  <Fragment>
    <p>
      <strong>{title}</strong>
    </p>
    <br />
    {children}
    <br />
  </Fragment>
);

export const RDevText = ({ title, startDate, endDate }) => (
  <Fragment>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp; + {title} &nbsp;&nbsp; [ {startDate} - {endDate}{" "}
      ]
    </p>
  </Fragment>
);

export const RTabText = ({ title }) => (
  <Fragment>
    <p>&nbsp;&nbsp;&nbsp;&nbsp; + {title}</p>
  </Fragment>
);

export const RPStatus = ({ status, launch }) => (
  <Fragment>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp; &#8226; Status -> {status} | {launch}
    </p>
  </Fragment>
);

export const RPDescription = ({ desc }) => (
  <Fragment>
    <p>&nbsp;&nbsp;&nbsp;&nbsp; # {desc}</p>
  </Fragment>
);

export const RPTodo = ({ todo }) => (
  <Fragment>
    <p>&nbsp;&nbsp;&nbsp;&nbsp; ~ Todo -> {todo}</p>
  </Fragment>
);

export const RPApp = ({ title, link, aText, desc }) => (
  <Fragment>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp; +{" "}
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        title={aText}
        class="underline"
      >
        {title}
      </a>{" "}
      | {desc}
    </p>
  </Fragment>
);

export const RPTools = ({ tools }) => (
  <Fragment>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {tools}
    </p>
  </Fragment>
);
