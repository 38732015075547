import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import OldDesign from "./components/OldDesign";
import { NewDesign } from "./components/NewDesign";
import {
  About,
  ResumeJan,
  ResumeAug,
  ResumeDec,
  ResumeMay
} from "./components/Pages";
import UnderDev from "./components/UnderDev";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={NewDesign} />
          <Route exact path="/blog" component={NewDesign} />
          <Route exact path="/resume" component={NewDesign} />
          <Route exact path="/old" component={OldDesign} />
          <Route exact path="/old/about" component={About} />
          <Route exact path="/old/work" component={OldDesign} />
          <Route exact path="/old/blog" component={UnderDev} />
          <Route exact path="/resume23" component={NewDesign} />
          <Route exact path="/resume22" component={NewDesign} />
          <Route exact path="/resume21" component={NewDesign} />
          <Route exact path="/resume20" component={NewDesign} />
          <Route exact path="/resume19" component={ResumeJan} />
          <Route exact path="/resume18" component={ResumeDec} />
          <Route exact path="/resume17" component={ResumeMay} />
          <Route exact path="/resume16" component={ResumeAug} />
          <Route exact path="/skill" component={UnderDev} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
