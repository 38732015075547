import React, { useState, useEffect } from 'react';

export const ThemeContext = React.createContext({});

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(JSON.parse(localStorage.getItem('theme')) || "light");

  useEffect(() => {
    localStorage.setItem('theme', JSON.stringify(theme));
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  const themeBg = theme === "dark" ? "#282c35" : "#ECF0F1";
  const themeTxt = theme === "dark" ? "#ffffffe0" : "#000000";
  const bodyBg = theme === "dark" ? "#171b21" : "#fff";
  const bodyTxt = theme === "dark" ? "#fff" : "#000";

  document.body.style.backgroundColor = themeBg;

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, themeBg, themeTxt, bodyBg, bodyTxt }}>
      {children}
    </ThemeContext.Provider>
  );
};